<template>
  <div class="upload-videos">
    <div class="video-dropzone" ref="videodropzone">
      <div class="dropzone-display">
        <div class="pt-4">
          <h3>Drop or click here to upload your content</h3>
          <uploading-video v-for="(file, index) in files" v-bind:key="file.file.uniqueIdentifier + index"
            :file="file.file" :status="file.status" :progress="file.progress" @cancel="cancelFile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resumable from "resumablejs";

import UploadingVideo from "./UploadingVideo";

export default {
  props: {
    fileType: Array,
    file_type_url: String
  },
  components: {
    UploadingVideo,
  },
  data() {
    return {
      files: [], // our local files array, we will pack in extra data to force reactivity
      r: false,
    };
  },
  methods: {
    // finds the file in the local files array
    findFile(file) {
      return (
        this.files.find(
          (item) =>
            item.file.uniqueIdentifier === file.uniqueIdentifier &&
            item.status !== "canceled"
        ) ?? {}
      );
    },
    // cancel an individual file
    cancelFile(file) {
      this.findFile(file).status = "canceled";
      file.cancel();
    },


  },
  mounted() {
    // chunkSize: 50 * 1024 * 1024,
    // maxFileSize: 2000000000,
    // init resumablejs on mount
    const baseURL = process.env.VUE_APP_API_URL;

    this.r = new Resumable({
      // http://127.0.0.1:8000
      target: `${baseURL}/file-upload`,
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("token"),
      },
      query: { file_type: this.file_type_url },
      fileType: this.fileType,
      maxChunkRetries: 1,
      testChunks: false,
      chunkSize: 20 * 1024 * 1024,
      maxFiles: 1,
      maxFileSize: 8000000000,
    });

    // Resumable.js isn't supported, fall back on a different method
    if (!this.r.support)
      return alert(
        "Your browser doesn't support chunked uploads. Get a better browser."
      );

    this.r.assignBrowse(this.$refs.videodropzone);
    this.r.assignDrop(this.$refs.videodropzone);

    // set up event listeners to feed into vues reactivity
    this.r.on("fileAdded", (file, event) => {
      file.hasUploaded = false;
      // console.log("this.files", this.files);
      // keep a list of files with some extra data that we can use as props
      // this.files.push({
      //   file,
      //   status: "uploading",
      //   progress: 0,
      // });

      this.files = [{
        file,
        status: "uploading",
        progress: 0,
      }];

      this.r.upload();
    });
    this.r.on("fileSuccess", (file, event) => {
      this.findFile(file).status = "success";
      // console.log(event);
      const eventData = JSON.parse(event);
      console.log(eventData);
      this.$emit('success', JSON.parse(event));
    });
    this.r.on("fileError", (file, message) => {
      console.log("error from file error : " , message);
      this.findFile(file).status = "error";
    });
    this.r.on("fileRetry", (file, event) => {
      this.findFile(file).status = "retrying";
    });

    this.r.on("fileRetry", (file, event) => {
      this.findFile(file).status = "retrying";
    });
    this.r.on("fileProgress", (file) => {
      // console.log('fileProgress', progress)
      var localFile = this.findFile(file);
      // if we are doing multiple chunks we may get a lower progress number if one chunk response comes back early
      var progress = file.progress();
      if (progress > localFile.progress) localFile.progress = progress;
    });
  },
};
</script>

<style lang="css">

</style>
